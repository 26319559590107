<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="
              $can(permissions.TEAM.POST_v1_teams.action, permissions.TEAM.POST_v1_teams.resource)
            "
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button :to="{ name: constRouter.TEAM_STORE.name }" variant="primary">
                <span class="text-nowrap">Thêm đội</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="
              $can(
                permissions.TEAM.GET_v1_teams__team_id.action,
                permissions.TEAM.GET_v1_teams__team_id.resource,
              )
            "
            variant="flat-success"
            class="btn-icon rounded-circle"
            :to="{
              name: constRouter.TEAM_VIEW.name,
              params: { id: data.item.id },
            }"
          >
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-tooltip title="Xem chi tiết" :target="`invoice-row-${data.item.id}-preview-icon`" />

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="
                $can(
                  permissions.TEAM.PUT_v1_teams__team_id.action,
                  permissions.TEAM.PUT_v1_teams__team_id.resource,
                )
              "
              :to="{
                name: constRouter.TEAM_EDIT.name,
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Sửa</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                $can(
                  permissions.TEAM.DELETE_v1_teams__team_id.action,
                  permissions.TEAM.DELETE_v1_teams__team_id.resource,
                )
              "
              @click.stop="showRemoveItemDialog(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Xóa</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      id="modal-primary2"
      v-model="removeDialog"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Disagree')"
      :ok-title="$t('Agree')"
      modal-class="modal-primary"
      centered
      :title="$t('Delete {resource}', { resource: $t('team') })"
      no-close-on-esc
      no-close-on-backdrop
      @ok="removeItem"
      @cancel="removeDialog = false"
      @hidden="removeDialog = false"
      @close="removeDialog = false"
    >
      <b-card-text>
        {{
          $t('Are you sure want to delete this {resource}?', {
            resource: $t('team'),
          })
        }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BAvatar,
  BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import constRouter from '@/constants/constRouter';
import permissions from '@/constants/permissions';
import { editStatus } from '@/apis/apiStaff';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { teamRemove } from '@/apis/apiTeam';
import UsersListFilters from './StaffListFilters.vue';
import useStaffList from './useStaffList';

export default {
  components: {
    UsersListFilters,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BAvatar,
    BMedia,
    BLink,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      // Extra Filters
      nameFilter,
      usernameFilter,
    } = useStaffList();

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      // Extra Filters
      nameFilter,
      usernameFilter,

      constRouter,
      permissions,
    };
  },
  data: () => ({
    colors: ['primary', 'warning', 'success', 'info', 'danger'],
    removeDialog: false,
  }),
  methods: {
    showRemoveItemDialog(item) {
      // show dialog
      this.removeDialog = true;

      // store item will remove
      // this.rmItems.push(item);
      this.rmItems = [item];
    },
    removeItem() {
      // turn of dialog
      this.removeDialog = false;
      // remove item from list
      this.rmItems.forEach((rmItem) => {
        // call api
        teamRemove(rmItem.id)
          .then(() => {
            this.refetchData();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Xoá đội',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Xoá đội thành công',
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Xảy ra lỗi',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Xoá đội thất bại',
              },
            });
          });
      });

      // clear rmItems
      this.rmItems = [];
    },
    clearFilter() {
      this.nameFilter = '';
      this.usernameFilter = '';

      this.refetchData();
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * 5)];
    },
    changeStatus(status, id) {
      editStatus(id, status)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sửa nhân viên',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Cập nhật trạng thái thành công!',
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sửa nhân viên',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Cập nhật trạng thái thất bại!',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
